<template>
  <div id="home" class="container" v-if="store.hasHomePage">
    <!-- <div class="menu-wrapper mobile-only">
      <div class="menu-opaque-overlay"></div>
      <div class="row row__menu mobile-only">
        <div class="menu__items">
          <router-link
            :to="{ name: 'catalog' }"
            :title="$t('Browse all products')"
            class="a-logo nav__title no-underline"
          >
            <font-awesome-icon
              :icon="['fas', 'th']"
              style="margin-right: 0.125rem;"
            />
            All Products
          </router-link>
        </div>
      </div>
    </div> -->
    <template v-for="(element, elementIndex) in elements">
      <SfSection class="banners-section" v-if="element.type === 'banners'" :key="elementIndex">
        <SlidingBanners :banners="element.data" />
      </SfSection>
      <SfSection v-if="element.type === 'categoryGroup' && element.categories.length" :title-heading="element.title"
        :key="elementIndex">
        <CategoryGrid class="banner-grid" :categories="element.categories" @click="toCategory"></CategoryGrid>
      </SfSection>
      <SfSection v-if="element.type === 'products' && element.products.length" :title-heading="element.title"
        :key="elementIndex">
        <div class="products" v-if="!isGroupedView">
          <transition-group appear name="products__slide" tag="div" class="products__grid">
            <ProductGridItem v-for="(product, index) in element.products" :key="product.id"
              :style="{ '--index': index }" :product="product" :is-cart-enabled="isCartEnabled"
              :show-stock-errors="showStockErrors" class="products__grid-item" :link="'/p/' + product.variantId" />
          </transition-group>
        </div>
        <div class="products" v-else>
          <transition-group appear name="products__slide" tag="div" class="products__grid">
            <CollectedProductGridItem v-for="(group, i) in element.products" :key="group[0].itemId"
              :style="{ '--index': i }" :products="group" :is-cart-enabled="isCartEnabled"
              :show-stock-errors="showStockErrors" class="products__grid-item" />
          </transition-group>
        </div>
        <!-- <SfCarousel class="carousel">
          <SfCarouselItem
            v-for="(product, index) in element.products"
            :key="index"
            class="carousel__item"
          >
            <ProductGridItem
              :style="{ '--index': index }"
              :product="product"
              class="products__product-card"
            />
          </SfCarouselItem>
        </SfCarousel> -->
      </SfSection>
    </template>
    <SfSection>
      <SfButton class="sf-button--full-width color-secondary cart-action all-products-btn"
        style="max-width: 400px; margin: 0 auto" @click.native="startShopping">
        {{ $t("See All Products") }}
      </SfButton>
    </SfSection>
    <SfSection style="text-align: center; opacity: 0.65; font-size: 14px" v-if="!store.hidePlatformBranding">
      <div style="display: inline-block; vertical-align: middle">
        Powered by
      </div>
      <a :href="getPoweredByLink()" style="
          display: inline-block;
          vertical-align: middle;
          height: 18px;
          width: 85px;
          margin-left: 5px;
        ">
        <img alt="Zobaze" src="https://pos-staging-0.web.app/assets/img/logo/zobaze%20logo.png"
          style="height: 100%; width: 100%" />
      </a>
    </SfSection>
  </div>
</template>
<script>
import {
  SfButton,
  SlidingBanners,
  SfSection,
  CategoryGrid,
  ProductGridItem,
  CollectedProductGridItem,
} from "@lib/components";
import { mapGetters, mapActions } from "vuex";

const colorToBackgroundMap = {
  red: "rgba(256,0,0,0.2)",
  blue: "rgba(0, 0, 256, 0.2)",
  brown: "rgba(181,101,29,0.4)",
  green: "rgba(0,256,0,0.1)",
  orange: "rgba(256,128,0,0.5)",
};

const getGroupedProducts = (products) => {
  let groups = {};
  for (let product of products) {
    groups[product.itemId] = groups[product.itemId] || [];
    groups[product.itemId].push(product);
  }
  return Object.values(groups);
};

export default {
  name: "Home",
  components: {
    SfButton,
    SlidingBanners,
    SfSection,
    CategoryGrid,
    ProductGridItem,
    CollectedProductGridItem,
  },
  computed: {
    ...mapGetters({
      store: "store",
      productList: "catalog/list",
      categoryList: "catalog/categoryBannerInfo",
    }),
    isCartEnabled() {
      return (
        this.store.orderConfig.orderEnabled ||
        this.store.orderConfig.enquiryEnabled
      );
    },
    showStockErrors() {
      return !this.store.orderConfig.allowOrderOnNoStock;
    },
    isGroupedView() {
      return this.store.catalog && this.store.catalog.enableVariantGrouping;
    },
    elements() {
      if (!this.store.homePageConfig) return null;
      const elements = [];
      const config = this.store.homePageConfig;
      for (let configItem of config.data) {
        const configElement = null;
        if (configItem.type === "banners") {
          configElement = { ...configItem };
        } else if (configItem.type === "categoryGroup") {
          let categoryData = [];
          if (configItem.categoryIds && configItem.categoryIds.length) {
            for (let catId of configItem.categoryIds) {
              let category = this.categoryGridMap[catId];
              if (category) {
                categoryData.push(category);
              }
            }
          } else {
            categoryData = this.categoryGridData;
          }
          configElement = { ...configItem, categories: categoryData };
        } else if (configItem.type === "products") {
          const enabledTags = configItem.selectionCriteria.tags || [];
          const enabledCategories =
            configItem.selectionCriteria.categories || [];
          let products = [];
          if (enabledTags.length) {
            const enabledProducts = this.productList.filter((p) => {
              return enabledTags.some(
                (tag) => p.tags && p.tags.length && p.tags.includes(tag)
              );
            });
            products = products.concat(enabledProducts);
          }
          if (enabledCategories.length) {
            const enabledProducts = this.productList.filter((p) => {
              return enabledCategories.some((catId) => p.categoryId === catId);
            });
            products = products.concat(enabledProducts);
          }
          configElement = {
            ...configItem,
            products: this.isGroupedView
              ? getGroupedProducts(products)
              : products,
          };
        }
        if (configElement) elements.push(configElement);
      }
      return elements;
    },
    categoryGridData() {
      return this.categoryList.map((x) => {
        return {
          ...x,
          buttonText: "Shop Now",
          backgroundColor: colorToBackgroundMap[x.colour] || "#999999",
        };
      });
    },
    categoryGridMap() {
      return this.categoryGridData.reduce((map, category) => {
        map[category.id] = category;
        return map;
      }, {});
    },
  },
  beforeMount() {
    if (!this.$store.state.store.hasHomePage) {
      this.$router.replace({ name: "catalog" });
    }
  },
  methods: {
    toggleWishlist(index) {
      return (this.products[index].isOnWishlist = !this.products[index]
        .isOnWishlist);
    },
    toCategory(catId) {
      this.$router.push({
        name: "catalog",
        query: { ci: catId },
      });
    },
    getCategoryGridData(catIds) {
      let categoryData = [];
      for (let catId of catIds) {
        let category = this.categoryGridMap[catId];
        if (category) {
          categoryData.push(category);
        }
      }
      return categoryData;
    },
    startShopping() {
      this.$router.push({
        name: "catalog",
      });
    },
    getPoweredByLink() {
      let link =
        "https://pos.zobaze.com/?utm_source=store-front&utm_medium=store-front&utm_campaign=store-front";
      if (window.screen && window.screen.width <= 720) {
        link =
          Math.random() > 0.5
            ? link
            : "https://play.google.com/store/apps/details?id=com.zobaze.pos&referrer=utm_source%3Dstore-front%26utm_medium%3Dstore-front%26utm_term%3Dstore-front%26utm_content%3Dstore-front%26utm_campaign%3Dstore-front";
      }
      return link;
    },
  },
};
</script>
<style lang="scss">
@use "~@lib/styles" as *;

#home {
  box-sizing: border-box;
  padding: 0 var(--spacer-sm);
  padding-bottom: 3.75rem;

  @include for-desktop {
    padding: 0;
    margin: 0 auto;
    margin-top: 3rem;
  }

  .sf-section {
    --section-margin: 1.2rem 0;
    --section-content-margin: 0 0 var(--spacer-xs) 0;

    @include for-desktop {
      --section-margin: 3rem 0;
      --section-content-margin: 0 0 var(--spacer-base) 0;
    }

    .sf-heading {
      --heading-text-align: left;
      --heading-title-font-size: var(--font-xl);
      --heading-title-font-weight: var(--font-bold);

      @include for-mobile {
        --heading-title-font-size: var(--font-lg);
      }

      &__title {
        --heading-title-color: var(--c-black-lighten);
      }
    }
  }

  .menu-wrapper {
    margin: 0 -8px;
    position: relative;
    height: 30px;
    padding: 0;
    margin: 0 calc(-1 * var(--spacer-sm));
    background-color: var(--c-primary);

    .menu-opaque-overlay {
      position: absolute;
      height: 30px;
      width: 100%;
      background: white;
      opacity: 0.3;
    }

    .menu__items {
      padding: 7px var(--spacer-sm);
    }

    .nav__title {
      box-sizing: border-box;
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      font-family: Raleway, sans-serif;
      color: white;
      font-weight: 400;
      line-height: 16px;

      &:hover {
        text-decoration: none;
        color: white;
      }

      @include for-desktop {
        width: auto;
        margin-right: 5%;
      }
    }
  }

  .full-width {
    margin-left: calc(-1 * var(--spacer-sm));
    margin-right: calc(-1 * var(--spacer-sm));
  }

  .carousel {
    margin: 0 calc(var(--spacer-sm) * -1) 0 0;

    @include for-desktop {
      margin: 0;
    }

    // todo: fix product shadow on carousel
  }

  .banner-grid {
    @include for-desktop {
      margin: var(--spacer-base) 0;
    }
  }

  .call-to-action {
    display: flex;
  }

  .instagram-grid {
    max-width: 60rem;
    margin: 0 auto;

    &__row {
      display: flex;

      &+& {
        margin: var(--spacer-xs) 0 0 0;

        @include for-desktop {
          margin: calc(var(--spacer-xl) / 2) 0 0 0;
        }
      }
    }

    &__col {
      flex: 1;
      margin: 0;

      &+& {
        margin: 0 0 0 var(--spacer-xs);

        @include for-desktop {
          margin: 0 0 0 calc(var(--spacer-xl) / 2);
        }
      }
    }
  }

  .app-banner {
    --banner-container-width: 100%;
    --banner-title-margin: var(--spacer-base) 0 var(--spacer-xl) 0;
    --banner-padding: 0 var(--spacer-2xl);
    --banner-title-font-size: var(--h1-font-size);
    --banner-subtitle-font-size: var(--font-xl);
    --banner-title-font-weight: var(--font-semibold);
    --banner-subtitle-font-weight: var(--font-medium);
    --banner-title-text-transform: capitalize;
    --banner-subtitle-text-transform: capitalize;
    display: block;
    min-height: 26.25rem;
    max-width: 65rem;
    margin: 0 auto;
    padding: 0 calc(25% + var(--spacer-2xl)) 0 var(--spacer-xl);

    &__call-to-action {
      --button-background: transparent;
      display: flex;
    }

    &__button {
      --image-width: 8.375rem;
      --image-height: 2.75rem;
      --button-padding: 0;

      &+& {
        margin: 0 0 0 calc(var(--spacer-xl) / 2);
      }
    }
  }

  .products {
    box-sizing: border-box;
    flex: 1;
    margin: 0;

    &__grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &-item {
        box-sizing: border-box;
        display: flex;
        flex: 0 1 50%;

        @include for-desktop {
          flex: 0 1 25%;
        }
      }

      @include for-desktop {
        padding: var(--spacer-xs);
      }
    }
  }

  .banners-section:first-of-type {
    margin-top: 1.5rem;
    // --header-padding: 0;

    &>.sf-heading {
      display: none;

      &>.sf-heading__title {
        display: none;
      }
    }
  }

  .all-products-btn {
    border-radius: 0.375rem;
    --button-background: var(--c-primary);
  }
}
</style>
